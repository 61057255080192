export const NoCodeTemplates: Record<
  'login' | 'publicLogin' | 'updatePassword' | 'userActivity' | 'mfaVerification',
  { interfaceId: string; interfacePageId: string; interfacePageSlug: string }
> = {
  login: {
    interfaceId: 'ua-governance',
    interfacePageSlug: 'login',
    interfacePageId: 'login',
  },
  publicLogin: {
    interfaceId: 'ua-governance',
    interfacePageSlug: 'public-login',
    interfacePageId: 'public_login',
  },
  updatePassword: {
    interfaceId: 'ua-governance',
    interfacePageSlug: 'update-password',
    interfacePageId: 'update_password',
  },
  mfaVerification: {
    interfaceId: 'ua-governance',
    interfacePageSlug: 'mfa-verification',
    interfacePageId: 'mfa_verification',
  },
  userActivity: {
    interfaceId: 'ua-settings',
    interfacePageSlug: 'ua-settings-user-activity',
    interfacePageId: 'ua-settings-user-activity',
  },
};
